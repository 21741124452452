<template>
  <div class="bg">
    <div class="content">
      <div class="formbox">
        <div class="titlebox">
          <div class="title">Welcome Kevin Cump!</div>
        </div>
        <!-- <div class="selectbox">
          <div class="text">What course would you like to teach?</div>
          <el-select v-model="value" placeholder="select">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="selectbox">
          <div class="text">Native language</div>
          <el-select v-model="value1"  multiple  placeholder="select">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="selectbox">
          <div class="text">Location</div>
          <el-select v-model="value2" multiple  placeholder="select">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="btn" @click="CONTINUE">CONTINUE</div>
        <div class="iconfont icon-guanbi" @click="guanbilgin"></div>
      </div>
    </div>
  </div>
</template>

<script>
import mInput from "./mInput.vue";
export default {
  props: { isloginform: Boolean },
  data() {
    return {
      options: [
        {
          value: "AEAS",
          label: "AEAS",
        },
        {
          value: "VCE",
          label: "VCE",
        },
        {
          value: "Special Writing",
          label: "Special Writing",
        },
        {
          value: "General Speaking",
          label: "General Speaking",
        },
      ],
      options1: [
        {
          value: "Chinese",
          label: "Chinese",
        },
        {
          value: "English",
          label: "English",
        },
      ],
      options2: [
        {
          value: "China",
          label: "China",
        },
        {
          value: "Australia",
          label: "Australia",
        },
      ],
      value: "",
      value1: "",
      value2: "",
    };
  },
  methods: {
    guanbilgin() {
      this.$emit("guanbilogin", false);
    },
    CONTINUE() {
      if (this.value1 != "" && this.value2 != "") {
        this.$router.push({ name: "Becometeacher", params: { language: this.value1,location:this.value2 } });
      }
    },
  },

  components: {
    mInput,
  },
};
</script>

<style scoped lang="scss">
.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.4);
  z-index: 1000;
  .content {
    position: relative;
    width: 100%;
    height: 100%;

    .formbox {
      position: absolute;
      left: 50%;
      top: 15rem;
      transform: translateX(-50%);
      background: #f9f9f8;
      width: 37.5rem;
      height: 29.75rem;
      background-color: #f9f9f8;
      border-radius: 8px;

      .titlebox {
        height: 4.6875rem;
        background-color: #fff;
        border-radius: 8px 8px 0 0;
        border-bottom: #f1f3f4 solid 1px;
        .title {
          font-size: 18px;
          font-family: HelveticaNeue;
          color: #333333;
          line-height: 4.6875rem;
        }
      }
      .selectbox {
        padding: 0 2.5rem;
        margin-bottom: 20px;
        .text {
          font-size: 12px;
          font-family: HelveticaNeue;
          color: #666666;
          text-align: left;
          margin: 10px;
        }
        /deep/ .el-select {
          width: 100%;
          border-radius: 10px !important;
          .el-input__inner:focus {
            border-color: #ffab00 !important;
          }
          .el-input.is-focus .el-input__inner {
            border-color: #ffab00 !important;
          }
          .el-input__inner {
            border-radius: 10px !important;
            height: 2.875rem;
            width: 100%;
          }
        }
      }
      .btn {
        margin: 40px auto;
        width: 32.5rem;
        height: 2.875rem;
        background: #ffab00;
        border-radius: 10px;
        font-size: 0.75rem;
        font-family: HelveticaNeue-Bold, HelveticaNeue;
        font-weight: bold;
        color: #ffffff;
        line-height: 2.875rem;
        &:hover {
          cursor: pointer;
        }
      }
      .iconfont {
        left: 50%;
        transform: translateX(-50%);
        bottom: -3.75rem;
        position: absolute;
        color: #d3cfc8;
        font-size: 3.125rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
