<template>
  <div class="bg">
    <div class="content">
      <div class="mylog">
        <div class="titlebox">
          <slot name="title"></slot>
          <div class="iconfont icon-guanbilog" @click="guanbilgin"></div>
        </div>
        <div class="mylogcontentbox">
          <slot name="mylogcontent"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mInput from "./mInput.vue";
export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    guanbilgin() {
      this.$emit("guanbilogin", false);
    },
  },

  components: {
    mInput,
  },
};
</script>

<style scoped lang="scss">
.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.4);
  z-index: 1000;
  .content {
    position: relative;
    width: 100%;
    height: 100%;
    .mylog {
      position: absolute;
      left: 50%;
      top: 30%;
      transform: translateX(-50%);
      background: #f9f9f8;
      width: 500px;

      border-radius: 8px;
      .titlebox {
        width: 100%;
        background: #f9f9f8;
        height: 4.6875rem;
        border-bottom: 1px solid #f1f3f4;
        border-radius: 8px 8px 0 0;
        .iconfont {
          position: absolute;
          right: 2.5rem;
          top: 77px;
          font-size: 0.75rem;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .mylogcontentbox {
        position: absolute;
        background: #f9f9f8;
        width: 100%;
        border-radius: 0 0 8px 8px;
      }
    }
  }
}
</style>
