





























































































import { Component, Emit, Vue } from "vue-property-decorator";
import topnavbar from "../components/topnavbar.vue";
import bottommenu from "../components/bottommenu.vue";
@Component({
  components: {
    topnavbar,
    bottommenu
  },
})

export default class teach extends Vue {}
