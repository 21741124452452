






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import bottommenu from "../components/bottommenu.vue";

import { warn } from "vue-class-component/lib/util";

@Component({
  components: {
    bottommenu,
  },
})
export default class Agreement extends Vue {
  count = 1;
  newcount = -1;
  islogin: boolean = false;
  isloginform: boolean = true;
  created() {
    console.log(this.$route);
    this.count = this.$route.query.type * 1;
  }

  private gohome() {
    this.$router.push({ name: "Home" });
  }
  private goto(data: number) {
    switch (data) {
      case 1:
        this.count = 1;
        window.scrollTo(0, 0);
        break;

      case 2:
        this.count = 2;
        window.scrollTo(0, 0);
        break;
      case 3:
        this.$router.push({ name: "starting" });
        break;
      case 4:
        this.$router.push({ name: "studyrooapps" });
        break;
      default:
        break;
    }
  }
}
