

























import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class mysteps extends Vue {
  @Prop({
    default: function () {
      return 0;
    },
  })
  activenum!: number;
}
