







































import { Component, Emit, Vue } from "vue-property-decorator";
import mylog from "@/components/mylog.vue";
import { State, Action, Mutation, Getter } from "vuex-class";
@Component({
  components: {
    mylog,
  },
})
export default class lessonmanagement extends Vue {
  @Getter("getUserMes") getUserMes: any;
  private checkListdata = [];
  private checkList = [];
  private zhou = [];
  private day = [];
  private input = "";
  private appendobj = [];
  created() {
    this.getweek();
    this.getDay();
    this.getallteachertime();
  }
  private shanchuappendobj(index: number) {
    this.appendobj.splice(index, 1);
  }
  private addappend() {
    this.appendobj.push({ week: null, day: null });
  }
  private getweek() {
    this.$get(this.$api.getdiclist + "?itemcode=ZHOU").then((res) => {
      console.log(res);
      this.zhou = res;
    });
  }
  private savebtn() {

    debugger
    let data = [];
    for (let index = 0; index < this.appendobj.length; index++) {
      let num = this.appendobj[index].week * 1 + this.appendobj[index].day * 1;
      data.push({ teacherId: this.getUserMes.id, weekIndex: num });
    }

    let newdata = { json: JSON.stringify(data) };
    debugger;
    this.$post(this.$api.savealltime, newdata).then((res) => {
      this.$message({
        message: " saved successfully",
        type: "success",
      });
    });
  }
  private getDay() {
    this.$get(this.$api.getdiclist + "?itemcode=SWHXW").then((res) => {
      this.day = res;
    });
  }
  private getallteachertime() {
    this.$get(this.$api.getallteachertime + "/" + this.getUserMes.id).then(
      (res) => {
        console.log(res);
        if (res.length > 0) {
          for (let index = 0; index < res.length; index++) {
            const element = res[index].weekIndex;
            this.appendobj.push({
              week: ((element%2 ==0)?element:element-1).toString(),
              day: (element % 2).toString(),
            });
          }
        } else {
          this.appendobj.push({ week: null, day: null });
        }
      }
    );
  }
}
