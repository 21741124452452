
















































































































































































































































































































































































































































































































































































































import { Component, Emit, Vue } from "vue-property-decorator";
import teachernavbar from "../components/teachernavbar.vue";
import bottommenu from "../components/bottommenu.vue";
import mysteps from "../components/mysteps.vue";
import { objUrl } from "../util/global";
import { State, Action, Mutation, Getter } from "vuex-class";
@Component({
  components: {
    teachernavbar,
    bottommenu,
    mysteps,
  },
})
export default class Becometeacher extends Vue {
  private activenum = -1;
  private teachertype = -1;
  private applydata = {};
  private language = "";
  private location = "";
  @Getter("getUserMes") getUserMes: any;
  @Mutation("setChangeUserMes") setChangeUserMes: any;
  private baseurl = objUrl.baseUrl;
  created() {
    this.getteacherdata();
    this.getLXGJ();
    console.log(this.getUserMes);
    for (let index = 0; index < this.$route.params.language.length; index++) {
      if (index == this.$route.params.language.length - 1) {
        this.language += this.$route.params.language[index];
      }else{
        this.language += this.$route.params.language[index]+',';
      }
    }
        for (let index = 0; index < this.$route.params.location.length; index++) {
      if (index == this.$route.params.location.length - 1) {
        this.location += this.$route.params.location[index];
      }else{
        this.location += this.$route.params.location[index]+',';
      }
    }
    console.log(this.language);
    console.log(     this.location);
  }
  //#region  step2content

  //#region  data
  private DisplayName = "";
  private value = "";
  private checked = false;
  private updataurl = "";
  private options = [
    {
      value: "1",
      label: "Wechat",
    },
    {
      value: "2",
      label: "Skype",
    },
    {
      value: "3",
      label: "Fbm",
    },
  ];
  private optionss = [
    {
      value: "0",
      label: "Zoom",
    },
  ];
  private country = [
    {
      value: "6177d3c9-ac81-6b94-006e-149e6ad29201",
      label: "Australia",
    },
  ];
  private province = [
    {
      value: "6177d445-ac81-6b94-006e-14a000d1ac19",
      label: "New South Wales",
    },
    {
      value: "6177d44f-ac81-6b94-006e-14a14070c0a2",
      label: "Queensland",
    },
    {
      value: "6177d458-ac81-6b94-006e-14a27a5af7e6",
      label: "South Australia",
    },
    {
      value: "6177d45e-ac81-6b94-006e-14a3323a8179",
      label: "Victoria",
    },
    {
      value: "6177d46b-ac81-6b94-006e-14a45105c27b",
      label: "Western Australia",
    },
    {
      value: "6177d478-ac81-6b94-006e-14a52e165769",
      label: "Tasmania",
    },
    {
      value: "6177d47f-ac81-6b94-006e-14a6798c1c29",
      label: "Northern Territory",
    },
    {
      value: "6177d488-ac81-6b94-006e-14a751c5f9ac",
      label: "Australian National Territory",
    },
  ];
  private MeetingLink = "";
  private MeetingID = "";
  private Passcode = "";
  private fromcountry = "";
  private fromprovince = "";
  private livingincountry = "";
  private livinginprovince = "";
  private FirstName = "";
  private LastName = "";
  private Gender = "";
  private Address = "";
  private imgurl = "";
  private Year = "";
  private Month = "";
  private Day = "";
  private gender = [
    {
      value: 0,
      label: "male",
    },
    {
      value: 1,
      label: "female",
    },
  ];
  private date = { year: [], month: [], day: [] };
  private appendobj = [];
  //#endregion

  private uplodsuccess(response, file, fileList) {
    this.imgurl = objUrl.baseImgUrl + response.data;
    this.updataurl = response.data;

    console.log(objUrl.baseImgUrl + response.data);
    console.log(file);
    console.log(fileList);
  }
  private addappendobj() {
    this.appendobj.push({ value: "", code: "" });
    console.log(this.appendobj);
  }
  private shanchuappendobj(index: number) {
    this.appendobj.splice(index, 1);
  }
  private monthchange() {
    this.getDay();
  }
  private getLXGJ() {
    this.$get(this.$api.getdiclist + "?itemcode=LXGJ").then((res) => {
      this.options = res;
    });
  }
  private addteachercontact() {
    let that = this;
    let userdata = {
      value: this.value,
      MeetingLink: this.MeetingLink,
      MeetingID: this.MeetingID,
      Passcode: this.Passcode,
    };
    let data = {
      teacherId: this.getUserMes.id,
      dataJson: JSON.stringify({
        zoomdata: userdata,
        appenddata: this.appendobj,
      }),
    };
    that.$post(that.$api.addteachercontact, data).then((res) => {
      console.log(res);
    });
  }
  //#endregion

  //#region  step3content
  private checkListdata = [];
  private textarea1 = "";
  private textarea2 = "";
  private videochecked1 = false;
  private videochecked2 = false;
  private videourl = "";
  private updatavideourl = "";

  private uplodvediosuccess(response, file, fileList) {
    debugger;
    this.updatavideourl = response.data;
    this.videourl = objUrl.baseImgUrl + response.data;
    console.log(this.videourl);
  }

  private uplodvedioprogress(response, file, fileList) {
    debugger;
  }
  //#endregion

  private xieyifirstname = "";
  private xieyilastname = "";
  //#region  date
  init() {
    this.getYear();
    this.getMonth();
    this.getDay();
  }
  getYear() {
    //获取年
    let date = new Date();
    let current_year = date.getFullYear();
    for (let i = 0; i < 100; i++) {
      let y = current_year - i;
      this.date.year.push({ value: y, label: y });
    }
  }
  getMonth() {
    //获取月
    for (let i = 1; i < 13; i++) {
      this.date.month.push({ value: i, label: i });
    }
  }
  getDay() {
    //获取日

    let day = this.getDaysInMonth(this.Year, this.Month);
    this.date.day = [];
    for (let i = 1; i <= day; i++) {
      this.date.day.push({ value: i, label: i });
    }
  }
  getDaysInMonth(year, month) {
    //获取某个月的天数
    month = parseInt(month, 10);
    let d = new Date(year, month, 0);
    return d.getDate();
  }
  padding0(num, length) {
    //数字前填充0方法
    for (let len = (num + "").length; len < length; len = num.length) {
      num = "0" + num;
    }
    return num;
  }

  //#endregion
  mounted() {
    this.init();
  }

  private getteacherdata() {
    this.$get(this.$api.getdatabyteacherid + "/" + this.getUserMes.id).then(
      (res) => {
        if (res == null) {
          this.activenum = 0;
        } else {
          this.applydata = res;
          this.activenum = 4;
          console.log(res);
          if (res.applyState) {
            this.$router.push({ name: "teacherhome" });
          }
        }
        console.log(res);
      }
    );
  }

  back() {
    this.activenum--;
    window.scrollTo(0, 0);
  }
  private creatteacherterms() {
    let that = this;
    let userdata = {
      teacherId: this.getUserMes.id,
      firstName: this.xieyifirstname,
      lastName: this.xieyilastname,
    };
    that.$post(that.$api.creatteacherterms, userdata).then((res) => {
      console.log(res);
    });
  }
  private updateteacher() {
    debugger;
    let element = "";
    for (let index = 0; index < this.checkListdata.length; index++) {
      debugger;
      if (index == this.checkListdata.length - 1) {
        element = element + this.checkListdata[index];
      } else {
        element = element + this.checkListdata[index] + ",";
      }
    }
    let userdata = {
      id: this.getUserMes.id,
      teacherType: this.teachertype,
      disPlayerName: this.DisplayName,
      from: this.fromcountry,
      from1: this.fromprovince,
      livingIn: this.livingincountry,
      livingIn1: this.livinginprovince,
      lastName: this.LastName,
      firstName: this.FirstName,
      birthDate:
        this.Year +
        "-" +
        this.padding0(this.Month, 2) +
        "-" +
        this.padding0(this.Day, 2),
      gender: this.Gender,
      address: this.Address,
      headImageUrl: this.updataurl,
      videoUrl: this.updatavideourl,
      abountMe: this.textarea1,
      lessonsTeachingStyleContent: this.textarea2,
      lessonsTeachingStyleType: element,
      speaks: this.language,
      location: this.location,
    };
    debugger;
    this.$put(this.$api.updateteacher, userdata).then((res) => {
      this.setChangeUserMes(res);
    });
  }
  next() {
    switch (this.activenum) {
      case 0:
        if (this.teachertype > -1) {
          this.activenum++;
          window.scrollTo(0, 0);
        }
        break;
      case 1:
        // this.activenum++;
        // window.scrollTo(0, 0);
        // console.log(this.fromcountry)
        // debugger
        debugger;
        if (
          this.DisplayName != "" &&
          this.value != "" &&
          this.checked &&
          this.MeetingLink != "" &&
          this.MeetingID != "" &&
          this.Passcode != "" &&
          this.fromcountry != "" &&
          this.fromprovince != "" &&
          this.livingincountry != "" &&
          this.livinginprovince != "" &&
          this.FirstName != "" &&
          this.LastName != "" &&
          this.Gender.toString() != "" &&
          this.Address != "" &&
          this.imgurl != "" &&
          this.Year != ""
        ) {
          this.activenum++;
          window.scrollTo(0, 0);
        } else {
          this.$message({
            message: "Please fill in all the information.",
            type: "warning",
          });
        }
        break;

      case 2:
        // this.activenum++;
        // window.scrollTo(0, 0);
        // console.log(this.checkListdata);
        debugger;
        if (
          this.checkListdata.length > 0 &&
          this.textarea1 != "" &&
          this.textarea2 != "" &&
          this.videochecked1 &&
          this.videochecked2 &&
          this.videourl != ""
        ) {
          console.log(this.checkListdata);
          this.activenum++;

          window.scrollTo(0, 0);
        } else {
          this.$message({
            message: "Please fill in all the information.",
            type: "warning",
          });
        }
        break;

      case 3:
        if (this.xieyifirstname != "" && this.xieyilastname != "") {
          this.creatteacherterms();
          this.addteachercontact();
          this.updateteacher();
          this.getteacherdata();
          this.activenum++;
          window.scrollTo(0, 0);
        } else {
          this.$message({
            message: "Please fill in all the information.",
            type: "warning",
          });
        }

        break;
      case 4:
        this.getteacherdata();
        window.scrollTo(0, 0);
        break;
      default:
        break;
    }
  }
}
