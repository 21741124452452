
















































































import { Component, Emit, Vue } from "vue-property-decorator";
import teachernavbar from "@/components/teachernavbar.vue";
import bottommenu from "@/components/bottommenu.vue";
@Component({
  components: {
    bottommenu,
    teachernavbar
  },
})
export default class teachersetting extends Vue {
  goto(){
    this.$router.push({  path: '/teachersetting/profile',})
  }
}
