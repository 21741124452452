







import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import login from "./login.vue";
import navbar2 from "./navbar2.vue";
@Component({
  components: {
    navbar2,
    login,
  },
})
export default class teachernavbar extends Vue {
  private isloginform: boolean = true;
  private islogin: boolean = false;

 private guanbilogin(data: boolean) {

    this.islogin = !this.islogin;
    this.isloginform = true;
  }
  private singuplogin(data: boolean) {
    this.islogin = !this.islogin;
    this.isloginform = false;
  }
}
