










import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { component } from "vue/types/umd";
import { State, Action, Mutation, Getter } from "vuex-class";
import { loginMes } from "../util/global";
@Component
export default class navbar2 extends Vue {
  @Prop() private loginbool!: boolean;
  @Getter("getUserMes") getUserMes: any;
  private login() {
    if (this.getUserMes.zyzg) {
      this.$router.push({ name: "profile" });
    }
  }
  @Mutation("clearUserMes") clearUserMes: any;

  private signup() {
    this.clearUserMes();
    this.$cookies.remove(loginMes);
    this.$router.push({ name: "Home" });
  }
  private goto() {

    if (this.getUserMes.zyzg) {
      this.$router.push({ name: "teacherhome" });
    }
  }
  private gohome() {
    this.$router.push({ name: "Home" });
  }
}
