
































import { Component, Emit, Vue } from "vue-property-decorator";
import teachernavbar from "../components/teachernavbar.vue";
import bottommenu from "../components/bottommenu.vue";
import { State, Action, Mutation, Getter } from "vuex-class";
import { objUrl } from "@/util/global";
@Component({
  components: {
    bottommenu,
    teachernavbar,
  },
})
export default class teacherhome extends Vue {
  @Getter("getUserMes") getUserMes: any;
  private imgurl = objUrl.baseImgUrl
  private data={}
  created () {
    this.getdata()
  }
  private goto() {
    this.$router.push({ name: "profile" });
  }
  private getdata() {
    this.$get(this.$api.getteacherinfo).then((res) => {
      debugger
      this.data = res;
    });
  }
}
