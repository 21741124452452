
























































































import { Component, Emit, Vue } from "vue-property-decorator";
import { State, Action, Mutation, Getter } from "vuex-class";
import { objUrl } from "@/util/global";
@Component({})
export default class teacherintroduction extends Vue {
  @Getter("getUserMes") getUserMes: any;
  @Mutation("setChangeUserMes") setChangeUserMes: any;
  private videochecked1 = true;
  private videochecked2 = true;
  private videourl = "";
  private showvideourl = "";
  private baseurl = objUrl.baseUrl;
  created() {
    this.videourl = objUrl.baseImgUrl+this.getUserMes.videoUrl;
    console.log(this.videourl )
  }

  private uplodvediosuccess(response, file, fileList) {
    this.showvideourl = response.data;
    this.videourl = objUrl.baseImgUrl + response.data;
    console.log(this.videourl);
  }

  private uplodvedioprogress(response, file, fileList) {
    debugger;
  }
  private updateteacher() {
    let userdata = {
      id: this.getUserMes.id,

      videoUrl: this.showvideourl,
    };

    this.$put(this.$api.updateteacher, userdata).then((res) => {
      this.setChangeUserMes(res);
    });
  }
}
