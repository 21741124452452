













































































































































































import { Component, Emit, Vue } from "vue-property-decorator";
import { State, Action, Mutation, Getter } from "vuex-class";
import mylog from "@/components/mylog.vue";
@Component({
  components: {
    mylog,
  },
})
export default class lessonmanagement extends Vue {
  @Getter("getUserMes") getUserMes: any;
  private checkListdata = [];
  private checkList = [];
  private textarea2 = "";
  private activecourse = "";
  private activelesson = "";
  private activelessontype = "";
  private checked = false;
  private checked1 = false;
  private checked2 = false;
  private checked3 = false;
  created() {
    this.getlesson();
  }
  private lessondata = [];
  private options = [
    {
      value: "1",
      label: "AEAS",
      id: "1",
      parentId: "",
      data: null,
      description: null,
      sort: "1",
      children: [],
    },
    {
      value: "2",
      label: "VCE",
      id: "2",
      parentId: "",
      data: null,
      description: null,
      sort: "2",
      children: [],
    },
    {
      value: "3",
      label: "Special Writing",
      id: "3",
      parentId: "",
      data: null,
      description: null,
      sort: "3",
      children: [],
    },
    {
      value: "4",
      label: "General Speaking",
      id: "4",
      parentId: "",
      data: null,
      description: null,
      sort: "4",
      children: [],
    },
  ];
  private value = "";
  private dialog1 = false;
  private dialog2 = false;
  private dialog3 = false;
  private input1 = 10;
  private input2 = 10;
  private input3 = 10;
  private input4 = 10;
  private trialPrices = "";

  private getlesson() {
    this.$get(this.$api.getlesson).then((res) => {
      this.lessondata = res;
    });
  }
  private changelessonname(data1, data2) {
    let data = this.lessondata
      .filter((res) => res.courseType == data1.courseType)[0]
      .children.filter((res) => res.id == data2.id)[0];
    this.$put(this.$api.updatelesson + "/" + data.id, data).then((res) => {
      this.$message({
        message: "lesson name saved successfully",
        type: "success",
      });
    });
  }
  private pricebtn(data1, data2) {
    this.activecourse = data1;
    this.activelesson = data2.id;
    this.activelessontype = data2.lessonType;
    this.dialog2 = true;
    this.trialPrices = data2.trialPrices;
  
    if (data2.minutePrices30 != -1) {
      this.checked = true;
    } else {
      this.checked = false;
    }
    if (data2.minutePrices45 != -1) {
      this.checked1 = true;
    } else {
      this.checked1 = false;
    }
    if (data2.minutePrices60 != -1) {
      this.checked2 = true;
    } else {
      this.checked2 = false;
    }
    if (data2.minutePrices90 != -1) {
      this.checked3 = true;
    } else {
      this.checked3 = false;
    }
    debugger;
    this.input1 = data2.minutePrices30 == null ? 10 : data2.minutePrices30;
    this.input2 = data2.minutePrices45 == null ? 10 : data2.minutePrices45;
    this.input3 = data2.minutePrices60 == null ? 10 : data2.minutePrices60;
    this.input4 = data2.minutePrices90 == null ? 10 : data2.minutePrices90;
  }
  private detailbtn(data1, data2) {
    this.activecourse = data1;
    this.activelesson = data2.id;
    this.textarea2 = data2.lessonDetailConetent;
    this.dialog3 = true;
  }
  private saveand() {
    debugger;
    let data = this.lessondata
      .filter((res) => res.courseType == this.activecourse)[0]
      .children.filter((res) => res.id == this.activelesson)[0];
    data.trialPrices = this.trialPrices * 1;
    if(data.lessonType=='1'){
      debugger;
      data.trialPrices=null
    }
debugger
    data.minutePrices30 = this.input1 * 1;
    data.minutePrices45 = this.input2 * 1;
    data.minutePrices60 = this.input3 * 1;
    data.minutePrices90 = this.input4 * 1;

    if (!this.checked) {
      data.minutePrices30 = -1;
    } else {
      if (data.minutePrices30 < 10 || data.minutePrices30 > 80) {
        this.$message({
          message: "The price of courses is unreasonable",
          type: "warning",
        });
        return;
      }
    }
    if (!this.checked1) {
      data.minutePrices45 = -1;
    } else {
      if (data.minutePrices45 < 10 || data.minutePrices45 > 80) {
        this.$message({
          message: "The price of courses is unreasonable",
          type: "warning",
        });
        return;
      }
    }
    if (!this.checked2) {
      data.minutePrices60 = -1;
    } else {
      if (data.minutePrices60 < 10 || data.minutePrices60 > 80) {
        this.$message({
          message: "The price of courses is unreasonable",
          type: "warning",
        });
        return;
      }
    }
    if (!this.checked3) {
      data.minutePrices90 = -1;
    } else {
      if (data.minutePrices90 < 10 || data.minutePrices90 > 80) {
        this.$message({
          message: "The price of courses is unreasonable",
          type: "warning",
        });
        return;
      }
    }

    if (
      data.trialPrices != "" && data.trialPrices != null&&
      (data.trialPrices * 1 < 10 || data.trialPrices * 1 > 80)
    ) {
      this.$message({
        message: "The price of courses is unreasonable",
        type: "warning",
      });
      return;
    }
    data.lessonDetailConetent = this.textarea2;
    debugger;
    this.$put(this.$api.updatelesson + "/" + data.id, data).then((res) => {
      console.log(res);
    });
    this.dialog2 = false;
    this.dialog3 = false;
  }
  private addlesson(data1) {
    //  let data = this.lessondata
    //   .filter((res) => res.courseType == data1)[0].app
    debugger;
    this.$post(this.$api.addlesson, {
      courseType: data1,
      teacherId: this.getUserMes.id,
    }).then((res) => {
      this.lessondata
        .filter((res) => res.courseType == data1)[0]
        .children.push(res);
    });
  }
  private addcourse() {
    this.$post(this.$api.addcourse + "/" + this.value).then((res) => {
      if (res == 1) {
        this.getlesson();
        this.dialog1 = false;
      }
    });
  }
  private deletecourse(data) {
    debugger;
    this.$DELETE(this.$api.deletecourse + "/" + data).then((res) => {
      this.getlesson();
    });
  }
  private deletelesson(data) {
    debugger;
    this.$DELETE(this.$api.deletelesson + "/" + data).then((res) => {
      this.getlesson();
    });
  }
}
